import React, { PropsWithChildren, useContext, useMemo } from "react";
import { FormInput } from "@k8slens/lds-form";

import { useBusinessSSO } from "src/hooks/useBusinessSSO";

import { BusinessContext } from "src/components/Business/Base";
import PageAction from "src/components/PageAction/PageAction";

import styles from "./OIDCSSOProviderForm.module.css";

export type OIDCFormField = {
  authorizationUrl: { value: string; valid: boolean };
  tokenUrl: { value: string; valid: boolean };
  jwksUrl: { value: string; valid: boolean };
  userInfoUrl: { value: string; valid: boolean };
  logoutUrl: { value: string; valid: boolean };
  issuer: { value: string; valid: boolean };
  clientSecret: { value: string; valid: boolean };
  clientId: { value: string; valid: boolean };
  oidcDiscoveryUrl: { value: string; valid: boolean };
};

export type OIDCFormFieldKey = keyof OIDCFormField;

export const OIDCSSOProviderForm = ({
  onSubmit,
  fields,
  dispatch,
}: PropsWithChildren<{
  onSubmit: () => void;
  fields: OIDCFormField;
  dispatch: React.Dispatch<{
    key: OIDCFormFieldKey;
    value: OIDCFormField[OIDCFormFieldKey]["value"];
    valid: OIDCFormField[OIDCFormFieldKey]["valid"];
  }>;
}>) => {
  const { businessId } = useContext(BusinessContext);
  const { creatingBusinessSSO } = useBusinessSSO(businessId);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onSubmit();
  };

  const valid = useMemo(
    () =>
      [fields.authorizationUrl, fields.tokenUrl, fields.clientId, fields.clientSecret].every(
        ({ valid }) => valid !== false,
      ),
    [fields.authorizationUrl, fields.tokenUrl, fields.clientId, fields.clientSecret],
  );

  return (
    <form aria-label="OIDC SSO Provider settings" autoComplete="on" onSubmit={handleSubmit}>
      <fieldset>
        <div className={styles.gridForm}>
          <FormInput
            label="Client id"
            name="clientId"
            value={fields.clientId.value}
            onChange={(value, valid) => dispatch({ key: "clientId", value, valid })}
            required
          />
          <FormInput
            label="Client secret"
            name="clientSecret"
            value={fields.clientSecret.value}
            onChange={(value, valid) => dispatch({ key: "clientSecret", value, valid })}
            required
          />
          <FormInput
            label="Authorization Url"
            type="url"
            name="singleSignOnServiceUrl"
            placeholder="https://login.xyz.com/id/oauth2/v2.0/authorize"
            value={fields.authorizationUrl.value}
            onChange={(value, valid) => dispatch({ key: "authorizationUrl", value, valid })}
            required
          />
          <FormInput
            wrapperProps={{ className: styles.tokenUrl }}
            label="Token URL"
            type="url"
            name="tokenUrl"
            placeholder="https://login.xyz.com/id/oauth2/v2.0/token"
            value={fields.tokenUrl.value}
            onChange={(value, valid) => dispatch({ key: "tokenUrl", value, valid })}
            required
          />
          <FormInput
            wrapperProps={{ className: styles.tokenUrl }}
            label="JWKS URL"
            type="url"
            name="jwksUrl"
            placeholder="https://login.xyz.com/id/discovery/v2.0/keys"
            value={fields.jwksUrl.value}
            onChange={(value, valid) => dispatch({ key: "jwksUrl", value, valid })}
            required
          />
          <FormInput
            wrapperProps={{ className: styles.tokenUrl }}
            label="User Info URL"
            type="url"
            name="userInfoUrl"
            placeholder="https://graph.xyz.com/oidc/userinfo"
            value={fields.userInfoUrl.value}
            onChange={(value, valid) => dispatch({ key: "userInfoUrl", value, valid })}
            required
          />
          <FormInput
            wrapperProps={{ className: styles.tokenUrl }}
            label="Logout URL"
            type="url"
            name="logoutUrl"
            placeholder="https://login.xyz.com/id/oauth2/v2.0/logout"
            value={fields.logoutUrl.value}
            onChange={(value, valid) => dispatch({ key: "logoutUrl", value, valid })}
            required
          />
          <FormInput
            wrapperProps={{ className: styles.tokenUrl }}
            label="Issuer"
            type="url"
            name="issuer"
            placeholder="https://login.xyz.com/id/v2.0"
            value={fields.issuer.value}
            onChange={(value, valid) => dispatch({ key: "issuer", value, valid })}
            required
          />
        </div>
        <PageAction
          label="Save single sign-on settings"
          buttonProps={{
            onClick: handleSubmit,
            disabled: !valid,
            loading: creatingBusinessSSO,
            textTransform: "capitalize",
          }}
        />
      </fieldset>
    </form>
  );
};
