import { useCallback, useEffect, useMemo, useState, useContext } from "react";
import clsx from "clsx";

import { LoadingIndicator, Notification, useConfirmDialog } from "@k8slens/lds";
import { FormSwitchGroup } from "@k8slens/lds-form";
import { SwitchValue } from "@k8slens/lds-form/lib/es/FormSwitchGroup/FormSwitchGroup";

import { useBusinessFeatures } from "src/hooks/useBusinessFeatures";
import { AIRGAPPED, OFFLINE_ACTIVATION } from "src/constants";
import { useIsEnterpriseFeaturesEnabled } from "src/hooks/useIsEnterpriseFeaturesEnabled";
import { useGetBusinessSubscriptions } from "src/hooks/useGetBusinessSubscriptions";
import { useAnalytics } from "src/hooks/useAnalytics";

import { BusinessContext } from "src/components/Business/Base";
import TrackedAnchor from "src/components/TrackedAnchor/TrackedAnchor";
import PageHeader from "src/components/PageHeader/PageHeader";
import PageAction from "src/components/PageAction/PageAction";

import styles from "./page.module.css";

const featureDescriptions: { [key: string]: string } = {
  [AIRGAPPED]:
    "Lens Desktop by default uses internet connections to access services such as auto-updating, auto-installation of tools, getting updates from Helm repositories or CVE databases, or accessing the support chat. In air-gapped or highly regulated environments this can create unwanted network traffic. Air-gapped mode allows you to centrally configure Lens Desktop for all users to stop all outbound internet traffic to assure network security. If Air-gapped mode is enabled Offline Activation will automatically be enabled for your Lens Business ID.",
  [OFFLINE_ACTIVATION]:
    "Offline Activation allows users in your Lens Business ID to activate Lens Desktop without an internet connection. This is useful for air-gapped environments or when you need to activate Lens Desktop on a machine that is not connected to the internet.",
};

const hiddenFeatures: string[] = [];

const FeatureManagement = () => {
  const { ConfirmDialog, confirm } = useConfirmDialog();

  const { businessId } = useContext(BusinessContext);
  const { loading: loadingSubscriptions, businessSubscriptions } = useGetBusinessSubscriptions(businessId);
  const { enterpriseFeaturesEnabled, loading } = useIsEnterpriseFeaturesEnabled(
    loadingSubscriptions ? undefined : businessSubscriptions,
  );
  const { track } = useAnalytics();

  const {
    businessFeatures,
    loadingBusinessFeatures,
    updateBusinessFeatures,
    updatingBusinessFeatures,
    errorLoadingBusinessFeatures,
    errorUpdatingBusinessFeatures,
  } = useBusinessFeatures(businessId);

  const [switches, setSwitches] = useState<Array<SwitchValue>>([]);

  const onSave = useCallback(() => {
    if (!businessId || !switches) {
      return;
    }

    const features = switches.map(({ id, checked }) => ({
      key: id,
      enabled: Boolean(checked),
    }));

    const airGapOn = switches.find((d) => d.id === AIRGAPPED && d.checked);
    const offlineActivationOff = switches.find((d) => d.id === OFFLINE_ACTIVATION && d.checked === false);

    if (airGapOn && offlineActivationOff) {
      setSwitches(switches.map((s) => (s.id === OFFLINE_ACTIVATION ? { ...s, checked: true } : s)));
    }

    track("Update Lens Enterprise Features");
    updateBusinessFeatures({
      businessId,
      features: features.map((f) => {
        if (f.key === OFFLINE_ACTIVATION && airGapOn) {
          return { ...f, enabled: true };
        }

        return f;
      }),
    });
  }, [businessId, switches, updateBusinessFeatures, track]);

  useEffect(() => {
    if (!businessFeatures) {
      return;
    }

    const switches = businessFeatures
      .map(
        ({ key, name, description, enabled }) => ({
          id: key,
          label: `Enable ${name}`,
          description: featureDescriptions[key] || description,
          checked: enabled,
        }),
        [],
      )
      .filter(({ id }) => !hiddenFeatures.includes(id));

    setSwitches(switches);
  }, [businessFeatures]);

  const hasChanges = useMemo(() => {
    if (!businessFeatures || !switches) {
      return false;
    }

    return switches.some(({ id, checked }) => {
      const feature = businessFeatures.find((feature) => feature.key === id);

      return feature?.enabled !== checked;
    });
  }, [businessFeatures, switches]);

  const isLoading = loading || loadingBusinessFeatures;

  const handleSwitchChange = useCallback(
    async (nextSwitches: Array<SwitchValue>, keys: Array<string>) => {
      const changed = nextSwitches.filter(({ id }) => keys.includes(id));

      if (changed.find((d) => d.id === AIRGAPPED && d.checked)) {
        // If user turns on the Airgapped mode, show a confirmation dialog
        const confirmed = await confirm(
          "Are you sure you want to enable airgapped mode?",
          <>
            <p>
              {/* eslint-disable-next-line max-len */}
              Air-gapped mode requires <strong>Offline Activation</strong> to be enabled and users in your Lens Business
              ID will be able to download <strong>Offline Activation Codes</strong>.
            </p>
            <p className="!mb-0">
              {/* eslint-disable-next-line max-len */}
              The associated Lens Subscription of the user will be fixed until the end of the billing cycle and you
              cannot re-assign the seat to other users.
            </p>
          </>,
          { confirmText: "Enable" },
        );

        if (confirmed) {
          // Set Offline Activation to enabled if Airgapped is enabled
          setSwitches(nextSwitches.map((s) => (s.id === OFFLINE_ACTIVATION ? { ...s, checked: true } : s)));
        }
      } else if (changed.find((d) => d.id === OFFLINE_ACTIVATION && d.checked === true)) {
        const confirmed = await confirm(
          "Are you sure you want to enable Offline Activation?",
          <>
            <p className="text-left">
              {/* eslint-disable-next-line max-len */}
              By enabling <strong>Offline Activation</strong> users in your Lens Business ID will be able to download{" "}
              <strong>Offline Activation Codes</strong>.
            </p>
            <p className="!mb-0 text-left">
              {/* eslint-disable-next-line max-len */}
              The associated Lens Subscription of the user will be fixed until the end of the billing cycle and you
              cannot re-assign the seat to other users.
            </p>
          </>,
          { confirmText: "Enable" },
        );

        if (confirmed) {
          setSwitches(nextSwitches);
        }
      } else if (changed.find((d) => d.id === OFFLINE_ACTIVATION && d.checked === false)) {
        const confirmed = await confirm(
          "Are you sure you want to disable Offline Activation?",
          // eslint-disable-next-line max-len
          <>
            The seats currently using dowloaded <strong>Offline Activation Codes</strong> will remain fixed until the
            end of the billing cycle.
          </>,
          { confirmText: "Disable" },
        );

        if (confirmed) {
          setSwitches(nextSwitches);
        }
      } else {
        setSwitches(nextSwitches);
      }
    },
    [confirm],
  );

  return (
    <div className={styles.featureManagement}>
      <PageHeader
        id="feature-settings"
        title="Feature Management"
        subtitle={
          <>
            {/* eslint-disable-next-line max-len */}
            Lens Pro includes additional features of Lens Desktop Kubernetes, Lens Security Center, Lens Teamwork, and
            Lens Support. Centralized feature management allows you to enable or disable these Lens Pro features across
            all users within their Lens Business ID.
          </>
        }
      />
      {isLoading && <LoadingIndicator size="2xl" />}
      {!isLoading && (
        <>
          {enterpriseFeaturesEnabled && (errorLoadingBusinessFeatures || errorUpdatingBusinessFeatures) && (
            <Notification
              type="flash"
              level="error"
              message={errorLoadingBusinessFeatures?.message || errorUpdatingBusinessFeatures?.message}
            />
          )}
          {!enterpriseFeaturesEnabled && (
            <Notification
              level="info"
              className={styles.enterpriseAccNotification}
              message={
                <>
                  {"Please "}
                  <TrackedAnchor href="https://k8slens.dev/purchase-request" target="_blank" rel="noreferrer">
                    contact sales
                  </TrackedAnchor>
                  {" to purchase Lens Enterprise subscription."}
                </>
              }
            />
          )}
          <section
            className={clsx({
              [styles.disabledSection]: !enterpriseFeaturesEnabled,
            })}
          >
            <form className={styles.form}>
              <div className={styles.switchGroupWrapper}>
                <FormSwitchGroup
                  wrapperProps={{ role: "group", "aria-labelledby": "feature-settings" }}
                  label=""
                  values={switches || []}
                  onChange={handleSwitchChange}
                  disabled={!enterpriseFeaturesEnabled || updatingBusinessFeatures}
                />
              </div>
              <PageAction
                label="Update Features"
                buttonProps={{
                  disabled: !hasChanges || loadingBusinessFeatures || updatingBusinessFeatures,
                  loading: updatingBusinessFeatures,
                  onClick: onSave,
                }}
              />{" "}
            </form>
          </section>
        </>
      )}
      {ConfirmDialog}
    </div>
  );
};

export default FeatureManagement;
