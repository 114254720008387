import { ChangeEvent, useRef, useState } from "react";
import { Notification } from "@k8slens/lds";
import { Input } from "@k8slens/lds";

import { base64ToXMLString, getIdentityProviderDetailsFromXML } from "src/utils/xml";

import { SAMLFormField, SAMLFormFieldKey } from "./SamlSSOProviderForm";

import styles from "./ImportSAML.module.css";

const reader = new FileReader();

export const ImportSAML = ({
  dispatch,
}: {
  dispatch: React.Dispatch<{
    key: SAMLFormFieldKey;
    value: SAMLFormField[SAMLFormFieldKey]["value"];
    valid: SAMLFormField[SAMLFormFieldKey]["valid"];
  }>;
}) => {
  const xmlInputRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<Error | null>(null);

  const selectXMLFile = () => {
    setError(null);

    if (xmlInputRef.current) {
      // Clear the input before picking a new file
      xmlInputRef.current.value = "";
      xmlInputRef.current.click();
    }
  };
  const importFromXML = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files;

    if (!file || !file[0]) {
      return;
    }

    reader.onload = () => {
      const resultString =
        reader.result && typeof reader.result !== "string" ? reader.result.toString() : reader.result;

      if (!resultString) {
        setError(new Error("File is empty. Please fill the form manually."));

        return;
      }

      try {
        const idpData = getIdentityProviderDetailsFromXML(base64ToXMLString(resultString));

        if (idpData.idpEntityId) {
          dispatch({ key: "samlSSOIdpEntityId", value: idpData.idpEntityId, valid: true });
        }

        if (idpData.singleSignOnServiceUrl) {
          dispatch({
            key: "samlSSOServiceUrl",
            value: idpData.singleSignOnServiceUrl,
            valid: true,
          });
        }
      } catch (error) {
        setError(
          new Error("Can't parse metadata. Please fill the form manually.", {
            cause: error,
          }),
        );
      }
    };

    reader.readAsDataURL(file[0]);
  };

  return (
    <>
      {error && (
        <Notification
          className={styles.error}
          level="error"
          message={error.message}
          type="flash"
          onClose={() => setError(null)}
        />
      )}
      <Input
        type="file"
        ref={xmlInputRef}
        onChange={importFromXML}
        wrapperProps={{ className: "hidden" }}
        accept="application/xml, text/xml"
      />
      <button onClick={selectXMLFile} className="text-sm text-brand-primary text-left">
        Import Configuration
      </button>
    </>
  );
};
