import { useState } from "react";
import { Modal, Notification } from "@k8slens/lds";
import { FormInput } from "@k8slens/lds-form";
import { LensSDKException } from "lens-platform-sdk";

import { useLensPlatformClient } from "src/hooks/useLensPlatformClient";

import Button from "src/components/Button/TrackedButton";

import { type OIDCFormField, type OIDCFormFieldKey } from "./OIDCSSOProviderForm";

import styles from "./ImportOIDC.module.css";

export const ImportOIDC = ({
  dispatch,
  oidcDiscoveryUrl,
}: {
  dispatch: React.Dispatch<{
    key: OIDCFormFieldKey;
    value: OIDCFormField[OIDCFormFieldKey]["value"];
    valid: OIDCFormField[OIDCFormFieldKey]["valid"];
  }>;
  oidcDiscoveryUrl: string;
}) => {
  const [importModalOpen, setImportModalOpen] = useState(false);
  const lensPlatformClient = useLensPlatformClient();
  const [error, setError] = useState<Error | null>(null);

  const onCancel = () => {
    setImportModalOpen(false);
  };

  const handleImport = async () => {
    try {
      const configRequestResult = await lensPlatformClient.sso.getRemoteOIODCConfiguration(oidcDiscoveryUrl);

      dispatch({ key: "jwksUrl", value: configRequestResult.jwksUrl, valid: true });
      dispatch({
        key: "authorizationUrl",
        value: configRequestResult.authorizationUrl,
        valid: true,
      });
      dispatch({ key: "issuer", value: configRequestResult.issuer, valid: true });
      dispatch({ key: "logoutUrl", value: configRequestResult.logoutUrl, valid: true });
      dispatch({ key: "userInfoUrl", value: configRequestResult.userInfoUrl, valid: true });
      dispatch({ key: "tokenUrl", value: configRequestResult.tokenUrl, valid: true });

      setError(null);
      dispatch({ key: "oidcDiscoveryUrl", value: "", valid: false });
      setImportModalOpen(false);
    } catch (error) {
      if (error instanceof LensSDKException) {
        setError(new Error(error?.message || "Failed to import OIDC configuration"));
      } else {
        setError(new Error("Unknown error"));
      }
      setImportModalOpen(false);
      dispatch({ key: "oidcDiscoveryUrl", value: "", valid: false });
    }
  };

  return (
    <>
      {error && (
        <Notification
          className={styles.error}
          level="error"
          message={error.message}
          type="flash"
          onClose={() => setError(null)}
        />
      )}
      {importModalOpen ? (
        <Modal
          title="Import from openid configuration"
          size="md"
          onClose={onCancel}
          isOpen={importModalOpen}
          footer={
            <>
              <Button label="Cancel" onClick={onCancel} />
              <Button label="Import" data-testId="Import" disabled={!oidcDiscoveryUrl} onClick={handleImport} primary />
            </>
          }
        >
          <p>Use openid configuration URL to auto-populate the OIDC fields.</p>
          <FormInput
            wrapperProps={{ className: styles.tokenUrl }}
            label="OIDC configuration discovery URL"
            type="url"
            name="oidcDiscoveryUrl"
            placeholder="https:/xyz.com/id/v2.0/.well-known/openid-configuration"
            value={oidcDiscoveryUrl}
            onChange={(value, valid) => dispatch({ key: "oidcDiscoveryUrl", value, valid })}
          />
        </Modal>
      ) : null}
      <button
        onClick={() => {
          setImportModalOpen(true);
        }}
        className={styles.textButton}
      >
        Import Configuration
      </button>
    </>
  );
};
