import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useBooleanFlagValue } from "@openfeature/react-sdk";
import clsx from "clsx";
import { LoadingIndicator, Notification } from "@k8slens/lds";
import { misc } from "@k8slens/lds-icons";

import { useIsEnterpriseFeaturesEnabled } from "src/hooks/useIsEnterpriseFeaturesEnabled";
import { useGetBusinessSubscriptions } from "src/hooks/useGetBusinessSubscriptions";
import { useBusinessSSO } from "src/hooks/useBusinessSSO";

import PageHeader from "src/components/PageHeader/PageHeader";
import PageSection from "src/components/PageSection/PageSection";
import TrackedAnchor from "src/components/TrackedAnchor/TrackedAnchor";

import { SCIMTokens } from "./SCIMTokens";
import { SingleSignOnConfig } from "./SingleSignOnConfig";
import { SCIMSwitch } from "./SCIMSwitch";
import { SSOSwitch } from "./SSOSwitch";

import styles from "./page.module.css";

const { VerifiedIcon } = misc;

const BusinessAuth = () => {
  const { businessId } = useParams<{ businessId?: string }>();
  const { loading: loadingBusinessSubscriptions, businessSubscriptions } = useGetBusinessSubscriptions(businessId);
  const { enterpriseFeaturesEnabled } = useIsEnterpriseFeaturesEnabled(businessSubscriptions);
  const { businessSSO, deletingBusinessSSO } = useBusinessSSO(businessId);
  const hasSSOConfig = Boolean(businessSSO?.config);
  const scimflag = useBooleanFlagValue("scim-token-ui", false);
  const [ssoChecked, setSSOChecked] = useState(false);
  const showSSOConfig = ssoChecked || (hasSSOConfig && enterpriseFeaturesEnabled);
  const showSCIM = scimflag && enterpriseFeaturesEnabled;

  const loading = loadingBusinessSubscriptions || deletingBusinessSSO;

  useEffect(() => {
    if (showSSOConfig) {
      setSSOChecked(true);
    }
  }, [showSSOConfig]);

  return (
    <div>
      <PageHeader title="Authentication"></PageHeader>
      {loading ? (
        <LoadingIndicator size="2xl" />
      ) : (
        <>
          {!enterpriseFeaturesEnabled && (
            <Notification
              level="info"
              className={styles.enterpriseAccNotification}
              message={
                <>
                  {"Please "}
                  <TrackedAnchor href="https://k8slens.dev/purchase-request" target="_blank" rel="noreferrer">
                    contact sales
                  </TrackedAnchor>
                  {" to purchase Lens Enterprise subscription."}
                </>
              }
            />
          )}
          <SSOSwitch checked={ssoChecked} setChecked={setSSOChecked} disabled={!enterpriseFeaturesEnabled} />
          {hasSSOConfig ? (
            <SSOConfigOK disabled={!enterpriseFeaturesEnabled} />
          ) : (
            <SSONotConfigured disabled={!enterpriseFeaturesEnabled} />
          )}
          {showSSOConfig ? <SingleSignOnConfig disabled={!enterpriseFeaturesEnabled} /> : null}
          {showSCIM ? <SCIMSwitch disabled={!enterpriseFeaturesEnabled} /> : null}
          {showSCIM ? <SCIMTokens disabled={!enterpriseFeaturesEnabled} /> : null}
        </>
      )}
    </div>
  );
};

function SSOConfigOK({ disabled }: { disabled?: boolean }) {
  return (
    <PageSection className={clsx({ [styles.disabled]: disabled })}>
      <VerifiedIcon className={styles.verifiedIcon} color="ok" />
      <span className={styles.verifiedText}>Your Lens Business ID SSO is successfully configured.</span>
    </PageSection>
  );
}

function SSONotConfigured({ disabled }: { disabled?: boolean }) {
  return (
    <PageSection className={clsx({ [styles.disabled]: disabled })}>
      <VerifiedIcon className={styles.unVerifiedIcon} />
      <span className={styles.unVerifiedText}>SSO not configured for your Lens Business ID.</span>
    </PageSection>
  );
}

export default BusinessAuth;
