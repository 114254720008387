import clsx from "clsx";

import PageSection from "src/components/PageSection/PageSection";

import { BusinessSSO } from "./BusinessSSO";

import styles from "./SingleSignOnConfig.module.css";

export const SingleSignOnConfig = ({ disabled = false }: { disabled?: boolean }) => {
  return (
    <PageSection
      className={clsx({
        [styles.disabled]: disabled,
      })}
    >
      {<BusinessSSO />}
    </PageSection>
  );
};
