import { type BusinessUserSubscriptionSeat } from "lens-platform-sdk/dist/cjs/BusinessService";

import { lastestActivatedAtBusinessUserSubscriptionSeat } from "src/services/lastestActivatedAtSeat";

const latestActivatedAtSeatIsOffline = (seats: Array<BusinessUserSubscriptionSeat>) => {
  const latestActivatedAtSeat: undefined | BusinessUserSubscriptionSeat = seats.reduce(
    lastestActivatedAtBusinessUserSubscriptionSeat,
    seats[0],
  );

  return Boolean(latestActivatedAtSeat?.offline);
};

export default latestActivatedAtSeatIsOffline;
