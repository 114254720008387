import { useContext, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import uniq from "lodash/uniq";
import { Notification } from "@k8slens/lds";

import { useBusiness } from "src/hooks/useBusiness";
import { useGetEmails } from "src/hooks/useGetEmails";
import { freeEmailDomain } from "src/services/freeEmailDomain";

import Select from "src/components/Select/Select";
import { BusinessContext } from "src/components/Business/Base";

import styles from "./BusinessSSOEmailAddressDomain.module.css";

const selectTitle = "Select a verified domain";

export const unSelected = {
  id: "unselected",
  label: "Select",
};

export const BusinessSSOEmailAddressDomain = ({
  selectedVerifiedDomain,
  setSelectedVerifiedDomain,
}: {
  selectedVerifiedDomain: {
    id: string;
    label: string;
  };
  setSelectedVerifiedDomain: React.Dispatch<
    React.SetStateAction<{
      id: string;
      label: string;
    }>
  >;
}) => {
  const history = useHistory();
  const { businessId, business } = useContext(BusinessContext);
  const { errorUpdatingBusiness, updateBusinessSuccess } = useBusiness(businessId);

  const { emails } = useGetEmails();
  const verifiedDomainsOptions = useMemo(() => {
    const domains = uniq(
      Object.keys(emails)
        .filter((email) => {
          const state = emails[email];

          if (state === "verified" || state === "primary") {
            if (freeEmailDomain.has(email.split("@")[1])) {
              return false;
            }

            return true;
          }

          return false;
        })
        .map((email) => {
          const domain = email.split("@")[1];

          return domain;
        }),
    );

    const options = domains.map((domain) => ({ label: domain, id: domain }));

    return options;
  }, [emails]);

  const verifiedDomains = useMemo(() => business?.verifiedDomains || [], [business?.verifiedDomains]);

  // set the selectedVerifiedDomain when verifiedDomains loads
  useEffect(() => {
    if (verifiedDomains.length === 0) {
      setSelectedVerifiedDomain(unSelected);
    } else {
      setSelectedVerifiedDomain({ id: verifiedDomains[0].domain, label: verifiedDomains[0].domain });
    }
  }, [verifiedDomains, setSelectedVerifiedDomain]);

  useEffect(() => {
    if (errorUpdatingBusiness) {
      setSelectedVerifiedDomain(unSelected);
    }
  }, [errorUpdatingBusiness, setSelectedVerifiedDomain]);

  return (
    <>
      <h3 className="lds-h4">Email Address Domain</h3>
      <p className="leading-normal">
        Choose the company email domain used for SSO from any of your verified email domains.
      </p>
      {errorUpdatingBusiness && !updateBusinessSuccess ? (
        <Notification level="error" message={errorUpdatingBusiness.message} type="closable" className="my-5" />
      ) : null}
      <div className={styles.domainSelectWrapper}>
        <Select
          lockIfSingleOption={false}
          title={selectTitle}
          value={selectedVerifiedDomain}
          onChange={(value) => {
            setSelectedVerifiedDomain(value);
          }}
          options={verifiedDomainsOptions}
        />
        <button
          onClick={() => {
            history.push(`/emails`);
          }}
          className={styles.textButton}
        >
          Your verified email domains
        </button>
      </div>
    </>
  );
};
