import { NumberBadge, SideNavGroup } from "@k8slens/lds";

import MainNavLink from "src/components/Layout/MainNavigationItem";

import styles from "./SideNavSettings.module.css";

interface Props {
  businessId?: string;
  businessRequestCount?: number;
  showBillingDetails?: boolean;
  isSupportEnabled?: boolean;
}

export const SideNavSettings = ({ businessId, businessRequestCount, showBillingDetails, isSupportEnabled }: Props) => {
  return (
    <SideNavGroup label="Settings">
      {businessId ? (
        <>
          {/* BusinessID items */}
          <MainNavLink to={`/business/${businessId}/profile`} title="Profile" />
          <MainNavLink to={`/business/${businessId}/auth`} title="Authentication" />
          <MainNavLink to={`/business/${businessId}/feature-flag-settings`} title="Feature Management" />
          <MainNavLink to={`/business/${businessId}/billing-details`} title="Billing Details" />
          <MainNavLink
            to={`/business/${businessId}/users`}
            title={
              <span className={styles.businessUsers}>
                <span>Users & Seats</span>
                <NumberBadge
                  className={styles.badge}
                  type="error"
                  value={businessRequestCount}
                  title={`${businessRequestCount} pending request(s).`}
                />
              </span>
            }
          />
          <MainNavLink to="/support" title="Support" />
        </>
      ) : (
        <>
          {/* LensID items */}
          <MainNavLink to={"/profile"} title="Profile" />
          <MainNavLink to={"/account"} title="Account" />
          <MainNavLink to="/connected-accounts" title="Connected Accounts" />
          <MainNavLink to="/billing-details" title="Billing Details" disabled={!showBillingDetails} />
          <MainNavLink to="/emails" title="Emails" />
          <MainNavLink to="/support" title="Support" disabled={!isSupportEnabled} />
        </>
      )}
    </SideNavGroup>
  );
};
